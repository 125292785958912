import ModulesList from '../../responsibility/searchModule';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'MenuDetails',
  components: {
    ModulesList,
    ModelSelect
  },
  props: ['menuObj'],
  data() {
    return {
      respMessage: null,
      editMode: true,
      loader: false,
      unsubscribe: null,
      showModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      modules: {
        value: null,
        text: null
      },
      menuId: 0,
      menuName: null,
      menuAction: null,
      menuStatus: false,
      menuDesc: null,
      menuTitle: null,
      status: { value: null, text: null },
      statusList: [],
      tab: null
    };
  },
  validations: {
    menuName: {
      required
    },
    menuTitle: {
      required
    },
    modules: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.seMenuObjValues();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        this.tab = actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditMenu();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.menu_id});
        }

      }
    });
  },
  methods: {
    addEditMenu() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          action_name: this.menuAction === '' ? null : this.menuAction,
          active: this.menuStatus,
          menu_description: this.menuDesc,
          menu_id: this.menuId,
          menu_name: this.menuName,
          module_id: this.modules.value,
          menu_title: this.menuTitle
        };
        this.loader = true;
        this.$store
          .dispatch('menu/addEditMenu', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.menuId = response.data.data.menu_id;
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    seMenuObjValues() {
      if (this.menuObj) {
        this.editMode = false;
        this.menuId = this.menuObj.menu_id;
        this.menuAction = this.menuObj.action_name;
        this.menuStatus = this.menuObj.active;
        this.menuDesc = this.menuObj.menu_description;
        this.menuName = this.menuObj.menu_name;
        this.modules.value = this.menuObj.module_id;
        this.modules.text = this.menuObj.module_name;
        this.menuTitle = this.menuObj.menu_title;
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },

    selectedRow(moduleData) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          value: moduleData.module_id,
          text: moduleData.module_name
        };
      }
      this.showModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
