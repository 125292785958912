import ModulesList from '../responsibility/searchModule';
import commonHelper from '@/app/utility/common.helper.utility';
import MenuDetails from './menuDetails';
export default {
  name: 'Menu',
  components: {
    ModulesList,
    MenuDetails
  },
  watch: {
    currentPage: function() {
      this.getMenuList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMenuList();
    }
  },
  data() {
    return {
      menuObj: null,
      loader: false,
      unsubscribe: null,
      showModal: false,
      payload: {},
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      modules: {
        value: null,
        text: null
      },
      menuName: null,
      actionName: null,
      menuTitle: null,
      tab: null,
      menuList: [],
      menuListFields: [
        {
          key: 'module_name'
        },
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'menu_name'
        },
        {
          key: 'menu_id',
          class: 'd-none'
        },
        {
          key: 'menu_description',
          label: 'Description'
        },
        {
          key: 'menu_title'
        },
        {
          key: 'action_name'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.getMenuList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.menuObj = null;
          this.openShowModal(true, 'MenuDetails');
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'menu/getMenuList',
            'menu',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getMenuList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.modules.value,
        menu_name: this.menuName,
        action_name: this.actionName,
        menu_title: this.menuTitle
      };
      this.loader = true;
      this.$store
        .dispatch('menu/getMenuList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.menuList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.modules = {
        value: null,
        text: null
      };
      this.menuName = null;
      this.actionName = null;
      this.menuTitle = null;
      this.getMenuList();
    },
    rowSelected(item) {
      this.menuObj = item;
      this.openShowModal(true, 'MenuDetails');
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
    },

    selectedRow(moduleData) {
      if (this.tab === 'ModulesList') {
        this.modules = {
          value: moduleData.module_id,
          text: moduleData.module_name
        };
      }
      this.showModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
